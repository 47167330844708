import React, {useState, useEffect} from "react";
import { supabase } from "../../api/supabaseClient";
import Navigation from "../navigation/Navigation";
import Product_quickview from "../product_quickview/Product_quickview";
import Hamburger_menu from "../navigation/hamburger_menu/Hamburger_menu";


const sortOptions = [
  { value: 'created_at', label: 'Newest' },
  { value: 'rating', label: 'Most Popular' },
  { value: 'price', label: 'Price: High' },
];

const Product_list = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false)
  const [products, setProducts] = useState()
  const [dataCounter, setDataCounter] = useState(3);
  const [orderBy, setOrderBy] = useState('created_at');
  const [selectedId, setSelectedId] = useState(1);


  let star = "★";



  useEffect(() => {

    setLoading(true)


    const fetchContact = async () => {
        const { data, error } = await supabase
            .from("products_list")
            .select("*, color(class)")
            .order(orderBy, {ascending: false})


        if (error) {
            setError(true)
            setProducts(null)
            console.log(error)
        }
        else if (data) {
            setProducts(data)
            setError(false)
            console.log(data)
        }
    }

    fetchContact()

    setLoading(false)

}, [orderBy]);


     const handleOptionChange = (event) => {
       setOrderBy(event.target.value);
     };



    const openModal = () => {
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
      };


      const handleSelectId = (id) => {
        setSelectedId(id);
      };



      const updateFavorites = (updatedFavorites) => {
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
      };


  return (
    <div>
    <div className="sm:hidden">
      <Hamburger_menu />
    </div>
    <Navigation />
    <div className="bg-white dark:bg-gray-800 dark:min-h-screen flex justify-center">
      <div className="w-4/5 min-h-10 mt-48 max-sm:mt-24">
        <div className="border-b pb-3 flex justify-between items-center">

        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Products list</h2>

        <select className="min-w-14 focus:outline-none dark:rounded-md" onChange={handleOptionChange}>
        <option className="hidden" disabled selected>Sort</option>
        {sortOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      </div>

      {products && <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {products.slice(0, dataCounter).map(item => (
            <div key={item.id} className="group relative cursor-pointer" onClick={() => {openModal(); handleSelectId(item.id)}}>
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={item.image}
                  alt={item.image}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <div className="mt-4 flex justify-between items-start">
                <div>
                  <h3 className="text-sm text-gray-700 dark:text-white">
                    <a>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {item.name}
                    </a>
                  </h3>
                  <p className="text-black dark:text-white">{star.repeat(item.rating)}</p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">${item.price}</p>
                </div>

                <ul className="flex gap-2">

                {item.color.map(color => (

                
                  <li style={{backgroundColor: color.class}} className="w-4 h-4 border rounded-full"></li>
                

                ))}

                </ul>

              </div>
            </div>
          ))}
        </div>}
        <button className="w-20 h-8 rounded-md text-white font-medium bg-indigo-600 hover:bg-indigo-700 mt-8" onClick={() => setDataCounter(dataCounter + 1)}>More</button>
      </div>
    </div>
    <Product_quickview isOpen={isOpen} closeModal={closeModal} updateFavorites={updateFavorites} id={selectedId} />
    </div>
  );
}

export default Product_list;
