import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'



const Shopping_cart = ({ isOpen, closeModal, updateCart }) => {


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false)


  useEffect(() => {

    const fetchData = async () => {

      setLoading(true);

      const storedData = localStorage.getItem('shopping-cart');
      if (storedData) {
        setData(JSON.parse(storedData));
        console.log(data)
      } else {
        setError(true);
      }

    }

    fetchData();

    setLoading(false);

  }, [updateCart]);



  const removeItem = (id) => {
    const updatedData = data.filter(item => item.isId !== id);
    setData(updatedData);
    localStorage.setItem('shopping-cart', JSON.stringify(updatedData));
  };

  const handleQuantityChange = (id, event) => {
    const updatedData = data.map((item) => {
      if (item.isId === id) {
        return { ...item, quantity: (event.target.value) };
      }
      return item;
    });
    setData(updatedData);
    localStorage.setItem('shopping-cart', JSON.stringify(updatedData));
  };


  const totalPrice = data.reduce((total, item) => total + item.price * item.quantity, 0); // Remove '$' sign and calculate total
  

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-slate-800 shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white">Shopping cart</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 dark:text-white dark:hover:text-gray-200 hover:text-gray-500"
                            onClick={() => closeModal(false)}
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul role="list" className="-my-6 divide-y divide-gray-200">
                            {data && data.map((item) => (
                              <li key={item.id} className="flex py-6">
                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 dark:border-none">
                                  <img
                                    src={item.isColor.image}
                                    alt={item.isColor.image}
                                    className="h-full w-full object-cover object-center"
                                  />
                                </div>

                                <div className="ml-4 flex flex-1 flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                      <h3>
                                        <a>{item.name}</a>
                                      </h3>
                                      <p className="ml-4">${item.price * item.quantity}</p>
                                    </div>
                                    <div className="flex justify-start items-center gap-2 mt-1">
                                    <div className="w-3 h-3 rounded-full" style={{backgroundColor: item.isColor.class}}></div>
                                    <p className="text-sm text-gray-500 dark:text-gray-200"> / {item.isSize.size}</p>
                                    </div>
                                  </div>
                                  <div className="flex flex-1 items-end justify-between text-sm">
                                    <select className='dark:rounded-sm'
                                      value={item.quantity}
                                      onChange={(e) => handleQuantityChange(item.isId, e)}
                                    >
                                      {[1, 2, 3, 4, 5, 6, 7, 8].map((qty) => (
                                        <option key={qty} value={qty}>
                                          Qty {qty}
                                        </option>
                                      ))}
                                    </select>


                                    <div className="flex">
                                      <button onClick={() => removeItem(item.isId)}
                                        type="button"
                                        className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-500 dark:hover:text-indigo-600"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                        <p>Subtotal</p>
                        <p>${totalPrice}</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500 dark:text-gray-400">Shipping and taxes calculated at checkout.</p>
                      <div className="mt-6">
                        <a
                          href="#"
                          className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                        >
                          Checkout
                        </a>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500 dark:text-gray-400">
                        <p>
                          or{' '}
                          <button
                            type="button"
                            className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-500 dark:hover:text-indigo-600"
                            onClick={() => closeModal(false)}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Shopping_cart;