export const footerData = {
    links: [
      {
        title: "Solutions",
        links: [
          {name: "Marketing", "url": "/marketing"},
          {name: "Analytics", "url": "/analytics"},
          {name: "Commerce", "url": "/commerce"},
          {name: "Insights", "url": "/insights"}
        ]
      },
      {
        title: "Support",
        links: [
          {name: "Pricing", "url": "/pricing"},
          {name: "Documentation", "url": "/documentation"},
          {name: "Guides", "url": "/guides"},
          {name: "API Status", "url": "/api-status"}
        ]
      },
      {
        title: "Company",
        links: [
          {name: "About", "url": "/about"},
          {name: "Blog", "url": "/blog"},
          {name: "Jobs", "url": "/jobs"},
          {name: "Press", "url": "/press"},
          {name: "Partners", "url": "/partners"}
        ]
      },
      {
        title: "Legal",
        links: [
          {name: "Claim", "url": "/claim"},
          {name: "Privacy", "url": "/privacy"},
          {name: "Terms", "url": "/terms"}
        ]
      }
    ]
  };