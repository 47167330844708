import React, { useEffect, useState } from "react";
import { supabase } from "../../../api/supabaseClient";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../navigation/Navigation";


const Update_product = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [price, setPrice] = useState();
    const [description, setDescription] = useState('');
    const [formError, setFormError] = useState(null);
    const [newImageSelected, setNewImageSelected] = useState(false);
    const [imagePreview, setImagePreview] = useState('');


    const handleSubmit = async (e) => {

        e.preventDefault()

        if (!name || !imageFile || !price || !description) {
            setFormError('Please fill in all the fields correctly')
            return
        }


        let image = "https://jmwyrkkavzoeoftqfhye.supabase.co/storage/v1/object/public/shop/clothing/" + imageFile.name;

        if (newImageSelected) {
        await uploadFile()
        } 
         else {
            image = imageFile;
        }

        const { data, error } = await supabase
           .from('products_list')
           .update({ name, image, price, description })
           .eq('id', id)
           .select()

           if (error) {
            console.log(error)
            setFormError('Please fill in all the fields correcly')
           }
            else if (data) {
            console.log(data)
            setFormError(null)
            navigate('/admin')
           }
    }


    useEffect(() => {
        const fetchContent = async () => {
            const { data, error } = await supabase
                .from('products_list')
                .select()
                .eq('id', id)
                .single()

            if (error) {
                navigate('/', { replace: true })
                console.log(error)
            }
            else if (data) {
                setName(data.name)
                setImageFile(data.image)
                setPrice(data.price)
                setDescription(data.description)
                console.log(data)
            }
        }

        fetchContent()

    }, [id, navigate])



    const handleImageChange = (e) => {
        const file = e.target.files[0];
    if (file) {
        setImageFile(file);
        setNewImageSelected(true);

        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    } 
    
    };

    


    const uploadFile = async () => {

        const avatarFile = imageFile

        const { data, error } = await supabase.storage
        .from('shop')
        .upload(`clothing/${avatarFile.name}`, avatarFile, {
        cacheControl: '3600',
        upsert: false
      })
        
        if (error) {
            console.error("Error uploading file:", error);
        } else {
            console.log("File uploaded successfully:", data);
        }
}

    return (
        <div className="w-full h-full flex">
            <div className="w-60 h-screen">
            <Navigation />
            </div>
        <div className="w-full h-full flex justify-center items-center">
            <form className="w-96 min-h-96 bg-white mt-16" onSubmit={handleSubmit}>

                <div className="w-full min-h-10 mb-8">
                    <h2 className="text-3xl text-black font-semibold">Update</h2>
                    <p className="text-gray-500 font-normal pt-1">Enter details to update</p>
                </div>


                <div className="relative w-full h-96">


                    <label for="doc" className="absolute bottom-0 left-0 flex items-center p-2 m-2 gap-2 rounded-3xl border border-gray-300 border-dashed bg-gray-50 cursor-pointer">
                    <svg className="h-5 w-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"/></svg>
                            
                            <h3 className="text-sm font-semibold text-gray-700">Upload a file</h3>
                            
                            <input 
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleImageChange(e)} 
                                id="doc" 
                                hidden 
                            />
                    </label>

                    <img className="w-full h-full object-cover object-center rounded-md" src={imagePreview || (imageFile)} />


                </div>



                <input className="w-full h-10 bg-transparent border border-gray-300 rounded-md pl-2 mt-5"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                />


                <input className="w-full h-10 bg-transparent border border-gray-300 rounded-md pl-2 mt-5"
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder="Price"
                />


                <textarea className="w-full h-20 bg-transparent border border-gray-300 rounded-md pl-2 mt-5"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                />


                <button className="w-full h-10 bg-[#E91E63] rounded-lg text-white font-medium mt-5" type="submit">Update Product</button>

                {formError && <p>{formError}</p>}
            </form>
        </div>
        </div>
    );
}

export default Update_product 