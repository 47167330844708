import React from "react";
import { supabase } from "../../../api/supabaseClient";
import { navigationData } from "./navigationData";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";



const Navigation = () => {

    let navigate = useNavigate()


    const handleLogout = async () => {
        await supabase.auth.signOut();
        Cookies.remove('token');
        navigate('/admin-login');
        window.location.reload();
      }

    return (
        <nav className="w-60 h-full fixed bg-slate-800">
            <ul className="w-full h-full flex flex-col justify-start items-center gap-10 pt-10">
                {navigationData.map((item, index) => (
                    <li className="w-4/5 hover:bg-blue-500 transition rounded-lg" key={index}>
                        <NavLink className={({ isActive }) =>
                         isActive ? 'text-white bg-blue-600 flex items-center rounded-lg gap-3 p-2' : 'text-white flex items-center gap-3 p-2'
                       } to={item.path}>

                            <img className="w-7 h-7" src={item.img} />
                            <p>{item.title}</p>
                        
                        </NavLink>
                    </li>
                ))}
                <button onClick={handleLogout} className="absolute bottom-10 w-20 h-10 rounded-lg bg-blue-500 hover:bg-blue-600 transition text-white font-medium">Logout</button>
            </ul>
        </nav>
    )
}

export default Navigation;