import React, { useEffect, useState } from "react";
import under_construction from "../../assets/img/under-construction.jpg";
import { motion, AnimatePresence } from "framer-motion";
import { initialTabs as tabs } from "./constructionData";


const Construction_modal = () => {

    const [openModal, setOpenModal] = useState(true);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [count, setCount] = useState(0);

    useEffect(() => {

        document.body.style.overflow = "hidden";

    }, []);

    useEffect(() => {


        const intervalId = setInterval(() => {
            if (count < 50) {
              setCount(prevCount => prevCount + 1);
            } else {
              clearInterval(intervalId);
            }
          }, 30); // Change the interval as needed
      
          return () => clearInterval(intervalId);

    }, [count]);



    const close = () => {
        setOpenModal(false);
    }



    const addScrollBar = () => {
        document.body.style.overflow = "auto";
    }

    return (


        <div>
            <motion.div className="fixed z-50 w-full h-screen bg-gray-500 opacity-90"
                animate={{
                    display: openModal ? "" : "none"
                }}
            />
            <AnimatePresence>
            {openModal && (
            <motion.div className="fixed z-50 w-full h-screen flex justify-center items-center"
                initial={{
                    y: "-100vh"
                }}
                animate={{
                    y: 0
                }}
                exit={{
                    y: "100vh"
                }}
                transition={{ type: "spring", stiffness: 50, duration: 2 }}
            >
                <div className="w-50 max-sm:w-95 h-35 flex bg-white">
                    <section className="w-2/4 max-sm:w-full h-full flex flex-col items-center p-5 relative">
                        <svg className="size-16 p-2 bg-gray-300 rounded-full fill-gray-700 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                        <h2 className="text-3xl text-gray-700 font-normal font-serif text-center py-2">Under Construction</h2>

                        <div className="w-full mt-2">
                            <nav>
                                <ul className="flex">
                                    {tabs.map((item) => (
                                        <li
                                            key={item.label}
                                            className={item === selectedTab ? "bg-slate-200 w-full" : "w-full"}
                                            onClick={() => setSelectedTab(item)}
                                        >
                                            <span className="flex items-center justify-center gap-1">
                                            <img className="w-9 h-9" src={item.icon} alt="" />
                                            <p>{item.label}</p>
                                            </span>
                                            {item === selectedTab ? (
                                                <motion.div className="h-0.5 bg-blue-600" layoutId="underline" />
                                            ) : null}
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                            
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        key={selectedTab.text}
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <p className="px-1 pt-5 text-gray-700">{selectedTab ? selectedTab.text : ""}</p>
                                    </motion.div>
                                </AnimatePresence>
                            
                        </div>

                        <div className="w-full absolute bottom-0">
                            <div className="w-full h-8 bg-gray-200">
                            <motion.section className="w-full h-8 bg-indigo-600 flex justify-end items-center pr-2"
                             animate={{
                                    width: ["0%", "50%"]
                                }}
                                transition={{
                                    duration: 1
                                }}
                            >
                                <p className="text-lg text-white font-semibold">{count}%</p></motion.section></div>
                        </div>

                    </section>
                    <section className="relative w-2/4 max-sm:w-0 h-full">
                        <svg onClick={() => { close(); addScrollBar() }} className="m-3 absolute right-0 fill-black p-1 bg-white rounded-full w-10 h-10 shadow-lg shadow-gray-700 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                        <img className="max-sm:hidden w-full h-full object-cover object-center" src={under_construction} alt="" />
                    </section>
                </div>
            </motion.div>
            )}
            </AnimatePresence>
        </div>

    )
}

export default Construction_modal;