import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { supabase } from '../../api/supabaseClient';
import admin_login_img from '../../assets/img/admin-login-img.png';
import Cookies from 'js-cookie';

const Login = ({setToken}) => {

    let navigate = useNavigate()

  const [loading, setLoading] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  


  useEffect(() => {

    setLoading(true)

    if (Cookies.get('token')) {
      navigate('/admin');
    }

    setLoading(false)

}, []);




  const handleLogin = async (e) => {

    e.preventDefault()

   
      const { user, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) {
        console.log(error)
        setError(true)
        
      } else if (!user) {
        setToken(!user)
        navigate('/admin')
        setError(false)
      }   

    }

    
  

  return (
    <div className="relative w-full h-screen flex justify-center items-center overflow-hidden">
      <img className='absolute w-full h-full object-cover object-center' src={admin_login_img} alt='tøj' />
      <div className='w-96 h-35 border-2 border-gray-300 rounded-3xl backdrop-blur-md'>
      <h1 className='text-white text-xl text-center py-16'>Admin Login</h1>
      <form className='w-full h-full flex flex-col justify-start items-center gap-12' onSubmit={handleLogin}>
      <input className='w-4/5 h-12 bg-transparent border-b-2 border-white text-white outline-none placeholder:text-white'
        type="email"
        placeholder="Email"
        required="true"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input className='w-4/5 h-12 bg-transparent border-b-2 border-white text-white outline-none placeholder:text-white'
        type="password"
        placeholder="Password"
        required="true"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button className='w-4/5 h-10 rounded-3xl bg-gray-200 outline-none cursor-pointer text-base font-semibold transition hover:bg-gray-300' type='submit'>Login</button>
      {error && <p className='text-lg font-normal text-yellow-300'>The email or password is incorrect</p>}
      </form>
      </div>
    </div>
  );
};

export default Login;
