
export const navigationData = [
    {
      title: "Shop",
      path: "/product-list"
    },
    {
      title: "About",
      path: "/about"
    },
    {
      title: "Gallery",
      path: "/gallery"
    }
  ];