import { Link } from "react-router-dom";




const Promo_category = () => {

    return (
        <div className="w-full min-h-10">
            <section className="w-full h-6 flex justify-between items-center">
                <h2 className="text-black dark:text-white text text-2xl font-bold">Shop by Category</h2>
                <Link to="/product-list" className="text-blue-600 dark:text-white font-semibold text-sm cursor-pointer">Browse categories →</Link>
            </section>
            <Link to="/product-list">
            <div className="w-full h-650 max-sm:h-35 grid grid-cols-2 grid-rows-2 gap-8 mt-6">
                <section className="flex justify-start items-end row-span-2 relative cursor-pointer">
                    <div className="text-white p-6 z-10">
                    <h3 className="font-semibold">New Clothes</h3>
                    <p className="text-sm">Shop now</p>
                    </div>
                    <img className="w-full h-full object-cover object-center rounded-lg brightness-90 absolute" src="https://tailwindui.com/img/ecommerce-images/home-page-03-featured-category.jpg" alt="" />
                </section>
                <section className="flex justify-start items-end h-full relative cursor-pointer">
                    <div className="text-white p-6 z-10">
                    <h3 className="font-semibold">Most Popular</h3>
                    <p className="text-sm">Shop now</p>
                    </div>
                    <img className="w-full h-full object-cover object-center rounded-lg brightness-90 absolute" src="https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg" alt="" />
                </section>
                <section className="flex justify-start items-end h-full relative cursor-pointer">
                    <div className="text-white p-6 z-10">
                    <h3 className="font-semibold">Good Price</h3>
                    <p className="text-sm">Shop now</p>
                    </div>
                    <img className="w-full h-full object-cover object-center rounded-lg brightness-90 absolute" src="https://tailwindui.com/img/ecommerce-images/home-page-03-category-02.jpg" alt="" />
                </section>
            </div>
            </Link>
        </div>
    );
}

export default Promo_category;