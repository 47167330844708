
export const sizeData = [
    {
      title: "XXS",
    },
    {
      title: "XS",
    },
    {
      title: "S",
    },
    {
      title: "M",
    },
    {
      title: "L",
    },
    {
      title: "XL",
    },
    {
      title: "XXL",
    },
    {
      title: "XXXL",
    }
  ];