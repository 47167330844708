import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../api/supabaseClient";
import { sizeData } from "./sizeData";
import Navigation from "../navigation/Navigation";

const Create_product = () => {
    const [name, setName] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [formError, setFormError] = useState(null);
    const [imagePreview, setImagePreview] = useState('https://jmwyrkkavzoeoftqfhye.supabase.co/storage/v1/object/public/shop/clothing/image-placeholder.jpg');
    const [sizeAndStock, setSizeAndStock] = useState({});
    const [sizeCount, setSizeCount] = useState(4);
    const [countColors, setCountColors] = useState(3); // Configurable number of colors
    const [colors, setColors] = useState(Array.from({ length: countColors }, () => ({ color: '#D3D3D3', imageFile: null, imagePreview: 'https://jmwyrkkavzoeoftqfhye.supabase.co/storage/v1/object/public/shop/clothing/image-placeholder.jpg' })));
    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isImageFileNotSelected = colors.some(color => color.color);
        const isColorImageNotSelected = colors.some(color => color.imageFile);
        
        

        if (!name || !imageFile || !price || !description || !isImageFileNotSelected || !isColorImageNotSelected || Object.keys(sizeAndStock).length === 0) {
            setFormError('Please fill in all the fields correctly');
            return;
        } else {
            const image = `https://jmwyrkkavzoeoftqfhye.supabase.co/storage/v1/object/public/shop/clothing/${imageFile.name}`;

            await Promise.all([
                uploadFile(imageFile),
                ...colors.map(color => color.imageFile && uploadFile(color.imageFile))
            ]);

            
                const { data: parentData, error: parentError } = await supabase
                    .from('products_list')
                    .insert([{ name, image, price, description }])
                    .select();

                const sizeInserts = Object.entries(sizeAndStock).map(([size, stock]) => ({
                    size_id: parentData[0].id,
                    size,
                    stock
                }));

                const colorInserts = colors.filter(color => color.imageFile).map(({ color: class_, imageFile }) => ({
                    color_id: parentData[0].id,
                    class: class_,
                    image: `https://jmwyrkkavzoeoftqfhye.supabase.co/storage/v1/object/public/shop/clothing/${imageFile.name}`
                }));

                await Promise.all([
                    supabase.from('size').insert(sizeInserts),
                    supabase.from('color').insert(colorInserts)
                ]);

                if (parentData) {
                console.log(parentData);
                setFormError(null);
                navigate('/admin');
                }
                else if (parentError) {
                console.error(parentError);
                setFormError('Please fill in all the fields correctly');
            }
        }
    };

    const handleCheckboxChange = (selectedSize) => {
        setSizeAndStock(prevSizeAndStock => ({
            ...prevSizeAndStock,
            [selectedSize]: prevSizeAndStock[selectedSize] || 0
        }));
    };

    const handleStockChange = (size, newStock) => {
        newStock = parseInt(newStock) >= 0 ? parseInt(newStock) : 0;
        setSizeAndStock(prevSizeAndStock => ({
            ...prevSizeAndStock,
            [size]: newStock
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleImageChangeColor = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const updatedColors = [...colors];
            updatedColors[index].imageFile = file;
            updatedColors[index].imagePreview = URL.createObjectURL(file);
            setColors(updatedColors);
        }
    };

    const uploadFile = async (avatarFile) => {
        
            const { data, error } = await supabase.storage
                .from('shop')
                .upload(`clothing/${avatarFile.name}`, avatarFile, {
                    cacheControl: '3600',
                    upsert: false
                });

             if (data) {   
                console.log("File uploaded successfully:", data);
            }
            else if (error) {
                console.error("Error uploading file:", error);
             }
    };

    const handleColorChange = (index, newColor) => {
        const updatedColors = [...colors];
        updatedColors[index].color = newColor;
        setColors(updatedColors);
    };


    const handleAddColor = () => {
        setCountColors(countColors + 1);
        setColors(prevColors => [
            ...prevColors,
            { color: '#D3D3D3', imageFile: null, imagePreview: 'https://jmwyrkkavzoeoftqfhye.supabase.co/storage/v1/object/public/shop/clothing/image-placeholder.jpg' }
        ]);
    };


    const handleRemoveColor = () => {
        if (countColors > 1) { // Ensure count doesn't go below 1
            setCountColors(countColors - 1); // Step 1: Update countColors state
            setColors(prevColors => prevColors.slice(0, -1)); // Step 2: Update colors state by removing the last color object
        }
    };

    return (
        <div className="w-full h-full flex">
            <div className="w-60 h-screen">
                <Navigation />
            </div>
            <div className="w-full h-full flex justify-center items-center">
                <form className="w-96 min-h-96 bg-white mt-16" onSubmit={handleSubmit}>

                    <div className="w-full min-h-10 mb-8">
                        <h2 className="text-3xl text-black font-semibold">Create</h2>
                        <p className="text-gray-500 font-normal pt-1">Enter details to create</p>
                    </div>

                    {/* Image upload */}
                    <div className="relative w-full h-96">
                        {/* Image upload input */}
                        <label htmlFor="doc" className="absolute bottom-0 left-0 flex items-center p-2 m-2 gap-2 rounded-3xl border-2 hover:border-[#E91E63] bg-gray-50 cursor-pointer">
                            <svg className="h-5 w-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" />
                            </svg>
                            <h3 className="text-sm font-semibold text-gray-700">Upload file</h3>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleImageChange(e)}
                                id="doc"
                                hidden
                            />
                        </label>

                        {/* Image preview */}
                        <img className="w-full h-full object-cover object-center rounded-md" src={imagePreview} alt="Image Preview" />
                    </div>

                    {/* Other input fields */}
                    <input
                        className="w-full h-10 bg-transparent border-2 focus:outline-none hover:border-[#E91E63] focus:border-[#E91E63] caret-[#E91E63] border-gray-300 rounded-md pl-2 mt-5"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        required
                    />
                    <input
                        className="w-full h-10 bg-transparent border-2 focus:outline-none hover:border-[#E91E63] focus:border-[#E91E63] caret-[#E91E63] border-gray-300 rounded-md pl-2 mt-5"
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="Price"
                        required
                    />
                    <textarea
                        className="w-full h-20 bg-transparent border-2 focus:outline-none hover:border-[#E91E63] focus:border-[#E91E63] caret-[#E91E63] border-gray-300 rounded-md pl-2 mt-5"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        required
                    />

                    {/* Color and Image upload for each color */}
                    {colors.map((color, index) => (
                        <div className="w-full flex justify-around items-center mt-4" key={index}>
                            {/* Color picker */}
                            <label htmlFor={`color-picker${index}`} className="relative block w-11 h-11 border-2 hover:border-[#E91E63] rounded-full cursor-pointer" style={{ backgroundColor: color.color }}>
                                <input
                                    id={`color-picker${index}`}
                                    className="absolute opacity-0"
                                    type="color"
                                    value={color.color}
                                    onChange={(e) => handleColorChange(index, e.target.value)}
                                />
                            </label>

                            {/* Image upload input for color */}
                            <div className="relative rounded-md">
                                <label htmlFor={`image-color${index}`} className="absolute bottom-0 w-full p-1 flex items-center gap-2 bg-white border-2 hover:border-[#E91E63] cursor-pointer">
                                    <svg className="h-5 fill-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                        <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" />
                                    </svg>
                                    <h3 className="text-sm text-black">Upload file</h3>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleImageChangeColor(e, index)}
                                        id={`image-color${index}`}
                                        hidden
                                    />
                                </label>
                                {/* Image preview for color */}
                                <img className="w-28 h-28 object-cover object-center rounded-md" src={color.imagePreview} alt={`Color ${index} Preview`} />
                            </div>
                        </div>
                    ))}
                    
                    <div className="w-full flex justify-center gap-5 my-8">

                        <button type="button" className="w-20 h-8 rounded-md text-white font-medium bg-indigo-600 hover:bg-indigo-700" onClick={handleAddColor}>More</button>

                        <button type="button" className="w-20 h-8 rounded-md text-white font-medium bg-indigo-600 hover:bg-indigo-700" onClick={handleRemoveColor}>Less</button>
                        
                    </div>

                    {/* Size input fields */}
                    {sizeData.slice(0, sizeCount).map((item, index) => (
                        <div className="flex justify-around items-center w-full h-20 mt-3" key={index}>
                            {/* Checkbox for size */}
                            <input
                                className="hidden peer"
                                id={item.title}
                                type="checkbox"
                                checked={sizeAndStock[item.title]}
                                onChange={() => handleCheckboxChange(item.title)}
                            />
                            <label htmlFor={item.title} className="flex justify-center items-center w-20 h-20 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">{item.title}</label>
                            {/* Input for stock */}
                            <input
                                className="w-20 h-20 border-2 focus:outline-none focus:border-[#E91E63] border-gray-200 hover:bg-gray-50 text-center rounded-lg"
                                type="number"
                                value={sizeAndStock[item.title] || 0}
                                onChange={(e) => handleStockChange(item.title, e.target.value)}
                                placeholder="Stock"
                            />
                        </div>
                    ))}





                    <div className="w-full flex justify-center gap-5 my-8">

                        <button type="button" className="w-20 h-8 rounded-md text-white font-medium bg-indigo-600 hover:bg-indigo-700" onClick={() => setSizeCount(sizeCount + 4)}>More</button>

                        <button type="button" className="w-20 h-8 rounded-md text-white font-medium bg-indigo-600 hover:bg-indigo-700" onClick={() => setSizeCount(4)}>less</button>

                    </div>

                    <button className="w-full h-10 bg-[#E91E63] rounded-lg text-white font-medium mt-5" type="submit">Create Product</button>

                    {formError && <p>{formError}</p>}
                </form>
            </div>
        </div>
    );
}

export default Create_product;