const Promo_summer = () => {
  return (
      <div className="w-full min-h-96 flex justify-between items-center p-10 max-sm:p-5 bg-gray-100 dark:bg-slate-900 rounded-2xl my-28">
      <div className="max-sm:w-full max-sm:h-full">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white max-sm:text-4xl">
          Summer styles are finally here
        </h1>
        <p className="w-50 max-xl:w-full max-2xl:w-96 mt-4 text-xl max-sm:text-lg text-gray-500 dark:text-white">
        This year's summer collection features vibrant colors, lightweight fabrics, and trendy designs perfect for any occasion.
        </p>
        <button className="rounded-md border border-transparent bg-indigo-600 px-8 max-sm:px-5 py-3 text-center font-medium text-white hover:bg-indigo-700 mt-8">
          Shop Collection
        </button>
      </div>
        <div className="max-xl:hidden max-sm:hidden max-sm:w-2/4 max-sm:h-full flex items-center space-x-6 lg:space-x-8">
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-64 w-44 max-sm:h-36 max-sm:w-20 overflow-hidden rounded-lg">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-01.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="h-64 w-44 max-sm:h-36 max-sm:w-20 overflow-hidden rounded-lg">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-02.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-64 w-44 max-sm:h-36 max-sm:w-20 overflow-hidden rounded-lg">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-03.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="h-64 w-44 max-sm:h-36 max-sm:w-20 overflow-hidden rounded-lg">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-04.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="h-64 w-44 max-sm:h-36 max-sm:w-20 overflow-hidden rounded-lg">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-05.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-64 w-44 max-sm:h-36 max-sm:w-20 overflow-hidden rounded-lg">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-06.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="h-64 w-44 max-sm:h-36 max-sm:w-20 overflow-hidden rounded-lg">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-03-hero-image-tile-07.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
        </div>
      </div>
  )
}

export default Promo_summer;
