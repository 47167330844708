import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { motion } from 'framer-motion';

const DarkModeSwitch = ({id}) => {

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const savedMode = Cookies.get('darkMode');
    setIsDarkMode(savedMode === 'true');
    applyDarkMode(savedMode === 'true');
  }, []);

  const applyDarkMode = (darkMode) => {
    const root = window.document.documentElement;
    if (darkMode) {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  };

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    Cookies.set('darkMode', newMode, { expires: 365 }); // Set the cookie to expire in 1 year
    applyDarkMode(newMode);
  };

  return (
      <label for={id} className="relative w-10 h-6 flex items-center cursor-pointer">
        
          <input
            type="checkbox"
            id={id}
            className="hidden"
            checked={isDarkMode}
            onChange={toggleDarkMode}
          />
          <div className="w-10 h-4 bg-gray-400 dark:bg-gray-400 rounded-full transition"></div>
          <motion.div
            className="absolute w-6 h-6 bg-white dark:bg-gray-800 rounded-full shadow-md transition-transform duration-300"
            animate={{ x: isDarkMode ? 17 : 0 }}
            transition={{ duration: 0.1 }}
            >
          </motion.div>
        
      </label>
  );
};

export default DarkModeSwitch;