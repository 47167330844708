import { Fragment, useState, useEffect } from 'react'
import { supabase } from '../../api/supabaseClient'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/20/solid'
import { Link } from "react-router-dom";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Product_quickview = ({ isOpen, closeModal, updateFavorites, id }) => {

  const [productQuickview, setProductQuickview] = useState()
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false)
  const [favorites, setFavorites] = useState(() => JSON.parse(localStorage.getItem('favorites')) || []);


  useEffect(() => {

    setLoading(true)


    const fetchContent = async () => {
      const { data, error } = await supabase
        .from("products_list")
        .select("*, color(*), size(*)")
        .eq("id", id)
        .single()
        .order('size', {foreignTable: 'size', ascending: false})


      if (error) {
        setError(true)
        setProductQuickview(null)
        console.log(error)
      }
      else if (data) {
        setProductQuickview(data)
        setError(false)
        console.log(data)
        setCurrentImage(data.image)
      }
    }

    fetchContent()

    setLoading(false)

  }, [id]);






  const toggleFavorite = (item) => {
    const existingIndex = favorites.findIndex(fav => fav.id === item.id);
    let updatedFavorites = [];
    if (existingIndex !== -1) {
      // Remove the item from favorites
      updatedFavorites = favorites.filter(fav => fav.id !== item.id);

    } else {
      // Add the item to favorites
      updatedFavorites = [...favorites, { ...item, isFavorite: true }];

    }
    setFavorites(updatedFavorites);
    updateFavorites(updatedFavorites)
  };




  const toggleShppingCart = (item) => {
    // Retrieve existing shopping cart data from local storage, if any
    const existingShoppingCart = JSON.parse(localStorage.getItem('shopping-cart')) || [];

    // Add the item to shopping cart
    const updatedFavorites = [
        ...existingShoppingCart,
        { ...item, isColor: selectedColor, isSize: selectedSize, quantity: 1, isId: newRandomNumber }
    ];

    // Update the shopping cart and local storage
    localStorage.setItem('shopping-cart', JSON.stringify(updatedFavorites));
};



  const [currentImage, setCurrentImage] = useState("")


  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const newRandomNumber = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    
  

  // Function to check if both color and size are selected
  const isFormValid = () => {
    return selectedColor && selectedSize;
  };



  return (
    <div>
      {productQuickview && <div>
          <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                    enterTo="opacity-100 translate-y-0 md:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 md:scale-100"
                    leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                  >
                    <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                      <div className="relative flex w-full items-center overflow-hidden bg-white dark:bg-slate-800 px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                        <button
                          className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 dark:text-white sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8 focus:outline-none"
                          onClick={() => closeModal(false)}
                        >

                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                          <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                            <img src={currentImage} alt={currentImage} className="object-cover object-center" />
                          </div>
                          <div className="sm:col-span-8 lg:col-span-7">
                            <h2 className="text-2xl font-bold text-gray-900 dark:text-white sm:pr-12">{productQuickview.name}</h2>

                            <section aria-labelledby="information-heading" className="mt-2">

                              <p className="text-2xl text-gray-900 dark:text-white">${productQuickview.price}</p>

                              {/* Reviews */}
                              <div className="mt-6">
                                <div className="flex items-center">
                                  <div className="flex items-center">
                                    {[0, 1, 2, 3, 4].map((rating) => (
                                      <StarIcon
                                        key={rating}
                                        className={classNames(
                                          productQuickview.rating > rating ? 'text-gray-900 dark:text-white' : 'text-gray-200 dark:text-gray-500',
                                          'h-5 w-5 flex-shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                    ))}
                                  </div>
                                  <Link to={'/product-overview/' + productQuickview.id} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-500 dark:hover:text-indigo-600">
                                    100 reviews
                                  </Link>
                                </div>
                              </div>
                            </section>

                            <section aria-labelledby="options-heading" className="mt-10">

                              <form onSubmit={(e) => {
                                e.preventDefault();
                                if (isFormValid()) {
                                  // Proceed with form submission
                                  toggleShppingCart(productQuickview);
                                  closeModal(false);
                                  setSelectedColor(null);
                                  setSelectedSize(null);
                                } else {
                                  alert('Please select both color and size.');
                                }
                              }}>
                                {/* Colors */}
                                <div>
                                  <h4 className="text-sm font-medium text-gray-900 dark:text-white">Color</h4>

                                  <RadioGroup className="mt-4">
                                    <span className="flex items-center space-x-3">
                                      {productQuickview.color.map((color) => (
                                        <RadioGroup.Option
                                          key={color.id}
                                          value={color}
                                          className={({ active, checked }) =>
                                            classNames(
                                              color.selected_class,
                                              active && checked ? 'ring ring-offset-1' : '',
                                              !active && checked ? 'ring-2' : '',
                                              'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                                            )
                                          }
                                        >
                                          <span
                                            onClick={() => {setCurrentImage(color.image); setSelectedColor(color)}}
                                            aria-hidden="true"
                                            className="h-8 w-8 rounded-full border border-black border-opacity-10"
                                            style={{ backgroundColor: color.class }}
                                            />
                                        </RadioGroup.Option>
                                      ))}
                                    </span>
                                  </RadioGroup>
                                </div>

                                {/* Sizes */}
                                <div className="mt-10">
                                  <div className="flex items-center justify-between">
                                    <h4 className="text-sm font-medium text-gray-900 dark:text-white">Size</h4>
                                    <Link to={'/product-overview/' + productQuickview.id} className="text-sm font-medium text-indigo-600 dark:text-indigo-500 dark:hover:text-indigo-600 hover:text-indigo-500">More details</Link>
                                  </div>

                                  <RadioGroup className="mt-4">
                                    <div className="grid grid-cols-4 gap-4">
                                      {productQuickview.size.map((size) => (
                                        <RadioGroup.Option onClick={() => setSelectedSize(size)}
                                          key={size.id}
                                          value={size}
                                          disabled={!size.stock}
                                          className={({ active }) =>
                                            classNames(
                                              size.stock
                                                ? 'cursor-pointer bg-white dark:bg-slate-600 dark:hover:bg-slate-700 text-gray-900 dark:text-white shadow-sm'
                                                : 'cursor-not-allowed bg-gray-50 dark:bg-slate-600 text-gray-200 dark:text-white',
                                              active ? 'ring-2 ring-indigo-500' : '',
                                              'group relative flex items-center justify-center rounded-md border dark:border-none py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1'
                                            )
                                          }
                                        >
                                          {({ active, checked }) => (
                                            <>
                                              <RadioGroup.Label as="span">{size.size}</RadioGroup.Label>
                                              {size.stock ? (
                                                <span
                                                  className={classNames(
                                                    active ? 'border' : 'border-2',
                                                    checked ? 'border-indigo-500' : 'border-transparent',
                                                    'pointer-events-none absolute -inset-px rounded-md'
                                                  )}
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <span
                                                  aria-hidden="true"
                                                  className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                                                >
                                                  <svg
                                                    className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                                    viewBox="0 0 100 100"
                                                    preserveAspectRatio="none"
                                                    stroke="currentColor"
                                                  >
                                                    <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                                  </svg>
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </RadioGroup.Option>
                                      ))}
                                    </div>
                                  </RadioGroup>
                                </div>

                                <div className="mt-10 flex gap-5">

                                  <button type="submit" className="w-full rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    Add to bag
                                  </button>

                                  <button onClick={() => toggleFavorite(productQuickview)} type="button" className="text-gray-400 px-3 py-3 rounded-md hover:bg-gray-100 dark:hover:bg-slate-700 cursor-pointer">
                                    <svg className={`flex-shrink-0 w-6 h-6 ${favorites.some(fav => fav.id === productQuickview.id) ? 'text-red-500 dark:text-white' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"></path></svg>
                                  </button>

                                </div>

                              </form>
                            </section>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
      </div>}
    </div>
  );
};

export default Product_quickview;