import welcome_img from "../../assets/img/welcome-img.jpg";
import Footer from "../../layout/footer/Footer";
import Navigation from "../../layout/navigation/Navigation";
import Promo_products from "../../layout/our_products/Promo_products";
import Product_quickview from "../../layout/product_quickview/Product_quickview";
import Promo_category from "../../layout/promo_category/Promo_category";
import Promo_summer from "../../layout/promo_summer/Promo_summer";
import { motion } from "framer-motion"
import Scroll_progress from "../../layout/scroll_progress/Scroll_progress";
import Construction_modal from "../../layout/construction_modal/Construction_modal";
import Hamburger_menu from "../../layout/navigation/hamburger_menu/Hamburger_menu";


const pageTrasition = {

  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeInOut",
      duration: 1,
      delay: 0.5,
    }
  }
}


const Home = () => {
  

    return(
      <div className="dark:bg-gray-800">
      <Navigation />
      <Hamburger_menu />
      <Construction_modal />
        <div className="w-full min-h-24 flex justify-center">
        <div className="w-95 min-h-24">
          <div className="relative w-full h-750 flex justify-start max-sm:justify-center items-center md:bg-parallax-welcome md:bg-cover md:bg-center md:bg-fixed">
          <img className="md:hidden absolute w-full h-full object-cover object-center" src={welcome_img} alt="" />
          <div className="pl-24 max-sm:pl-0 z-10">
          <h1 className="w-96 max-sm:w-60 text-white text-7xl max-sm:text-5xl font-medium pb-2.5">Show Your Brilliance</h1>
          <p className="w-80 max-sm:w-60 text-white">Lorem ipsum dolor sit amet sed do eiusmod tempor incididunt ut labore.</p>
          <button className="w-36 h-11 bg-white dark:bg-indigo-600 dark:text-white dark:hover:bg-indigo-700 hover:bg-slate-100 transition-all text-black font-semibold rounded-md border-none cursor-pointer mt-8">SHOP NOW</button>
          </div>
          </div>

          <motion.div
          variants={pageTrasition}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          >
          <Promo_summer />
          </motion.div>

          <motion.div
          variants={pageTrasition}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          >
          <Promo_category />
          </motion.div>

          <motion.div
          variants={pageTrasition}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          >
          <Promo_products />
          </motion.div>

          <Scroll_progress />

          <Footer />
          </div>
        </div>
        </div>
    )
}

export default Home;