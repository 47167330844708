import react_icon from "../../assets/img/react.svg";
import tailwind_icon from "../../assets/img/tailwind.svg";
import supabase_icon from "../../assets/img/supabase.svg";


export const allIngredients = [
    { icon: react_icon, label: "React", text: "React er et populært JavaScript-bibliotek til opbygning af brugergrænseflader. Det bruger en komponentbaseret tilgang, hvilket gør det nemt at opdele komplekse brugergrænseflader i genanvendelige og let forståelige komponenter. React giver også mulighed for effektiv håndtering af tilstand gennem brugen af ​​state og props, hvilket resulterer i en mere dynamisk og interaktiv brugeroplevelse." },
    { icon: tailwind_icon, label: "Tailwind", text: "Tailwind CSS er et utility-first CSS-framework, der giver udviklere mulighed for at opbygge brugergrænseflader ved hjælp af små, genanvendelige klasser i stedet for at skrive CSS fra bunden. Dette gør det hurtigt og nemt at oprette en stilfuld og responsiv design, samtidig med at man bevarer fleksibiliteten til at tilpasse designet efter behov." },
    { icon: supabase_icon, label: "Supabase", text: "Supabase er en open source backend-as-a-service platform, der giver udviklere mulighed for at oprette og administrere databaser, autentifikation, filopbevaring og andre backend-funktionaliteter. Supabase bygger på PostgreSQL-databasen og tilbyder en sikker og skalerbar løsning til at håndtere brugerdata og backend-logik, hvilket gør det nemt at udvikle og skalere webapplikationer." },
  ];
  
  const [react, tailwind, supabase] = allIngredients;
  export const initialTabs = [react, tailwind, supabase];