import React from "react";
import { motion, useScroll } from "framer-motion";



const Scroll_progress = () => {

    const { scrollYProgress } = useScroll();



   const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth" // Smooth scrolling animation
        });
      };

    return(
        <div onClick={scrollToTop} className="fixed bottom-0 right-5 max-sm:right-0 flex justify-center items-center cursor-pointer">
        <svg className="-rotate-90 fill-none" width="100" height="100" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="25" strokeWidth="5" className="stroke-slate-500 opacity-30" />
        <motion.circle
          cx="50"
          cy="50"
          r="25"
          strokeWidth="5"
          className="stroke-black dark:stroke-white"
          style={{ pathLength: scrollYProgress }}
        />
      </svg>
      <svg className="absolute w-7 h-7 dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"/></svg>
      </div>
    );
}

export default Scroll_progress;