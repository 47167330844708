import dashboard from '../../../assets/img/dashboard.svg';
import admin_users from '../../../assets/img/admin-users.svg';
import users from '../../../assets/img/users.svg';
import notification from '../../../assets/img/bell.svg';
import settings from '../../../assets/img/gear.svg';




export const navigationData = [
    {
      title: "Dashboard",
      path: "/admin",
      img: dashboard
    },
    {
      title: "Admin users",
      path: "/admin-users",
      img: admin_users
    },
    {
      title: "Users",
      path: "/users",
      img: users
    },
    {
      title: "Notification",
      path: "/notification",
      img: notification
    },
    {
      title: "Settings",
      path: "/settings",
      img: settings
    }
  ];