import React, {useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home';
import Product_overview from './layout/product_overview/Product_overview';
import Product_list from './layout/product_list/Product_list';
import Favorite_list from './layout/favorite_list/Favorite_list';
import Login from './layout/admin/Login';
import Home_admin from './pages/admin/Home_admin';
import Cookies from 'js-cookie';
import Create_product from './pages/admin/create_product/Create_product';
import Update_product from './pages/admin/update_product/Update_product';
import DarkModeSwitch from './layout/dark_mode_switch/DarkModeSwitch';


const App = () => {


  const [token, setToken] = useState('')


  if(token) {

    Cookies.set('token', true)

  }


  useEffect(() => {

    
      let data = Cookies.get('token');
      setToken(data);
      
    
  }, [token])
  
  return (
    <div>
      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/product-overview/:id' element={<Product_overview />} />
        <Route path='/product-list' element={<Product_list />} />
        <Route path='/favorite-list' element={<Favorite_list />} />
        <Route path='/admin-login' element={<Login setToken={setToken}/>} />
        <Route path='/switch' element={<DarkModeSwitch />} />

        
       {token && <Route path='/admin' element={ <Home_admin />} />}
       {token && <Route path='/admin-create' element={<Create_product />} />}
       {token && <Route path='/update-project/:id' element={<Update_product />} />}

      </Routes>
    </div>
  );
}

export default App;