import React, { useState, useEffect } from 'react';
import { supabase } from '../../api/supabaseClient';
import { StarIcon } from '@heroicons/react/20/solid';
import { RadioGroup } from '@headlessui/react';
import AccordionItem from '../accordion/Accordion';
import Navigation from '../navigation/Navigation';
import { Link, useParams } from 'react-router-dom';
import Hamburger_menu from '../navigation/hamburger_menu/Hamburger_menu';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Product_overview = () => {


  const { id } = useParams();

  const [productOverview, setProductOverview] = useState()
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false)
  const [favorites, setFavorites] = useState(() => JSON.parse(localStorage.getItem('favorites')) || []);

  useEffect(() => {

    setLoading(true)


    const fetchContent = async () => {
      const { data, error } = await supabase
        .from("products_list")
        .select("*, faq(*), color(*), size(*)")
        .eq("id", id)
        .single()
        .order('id', { foreignTable: 'faq', ascending: true})


      if (error) {
        setError(true)
        setProductOverview(null)
        console.log(error)
      }
      if (data) {
        setProductOverview(data)
        setError(false)
        console.log(data)
        setCurrentImage(data.image)
      }
    }

    fetchContent()

    setLoading(false)

  }, [id]);



  const toggleFavorite = (item) => {
    const existingIndex = favorites.findIndex(fav => fav.id === item.id);
    let updatedFavorites = [];
    if (existingIndex !== -1) {
      // Remove the item from favorites
      updatedFavorites = favorites.filter(fav => fav.id !== item.id);
      
    } else {
      // Add the item to favorites
      updatedFavorites = [...favorites, { ...item, isFavorite: true }];
      
    }
    setFavorites(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };


  const toggleShppingCart = (item) => {
    // Retrieve existing shopping cart data from local storage, if any
    const existingShoppingCart = JSON.parse(localStorage.getItem('shopping-cart')) || [];

    // Add the item to shopping cart
    const updatedFavorites = [
        ...existingShoppingCart,
        { ...item, isColor: selectedColor, isSize: selectedSize, quantity: 1, isId: newRandomNumber }
    ];

    // Update the shopping cart and local storage
    localStorage.setItem('shopping-cart', JSON.stringify(updatedFavorites));
};



  const [currentImage, setCurrentImage] = useState("")


  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const newRandomNumber = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    
  

  // Function to check if both color and size are selected
  const isFormValid = () => {
    return selectedColor && selectedSize;
  };


  return (
    <div>
      <div className="sm:hidden">
      <Hamburger_menu />
    </div>
      <Navigation />
      <div className="bg-white dark:bg-slate-800 flex justify-center">
        {productOverview && <div className="w-4/5 flex max-sm:flex-col justify-center items-start max-sm:items-center gap-7 mt-40 max-sm:mt-24">
          {/* Image gallery */}

          <div className="w-1/2 max-sm:w-full max-sm:h-[25rem] h-[50rem]">
          <img src={currentImage} alt={currentImage} className="w-full h-[42rem] max-sm:h-[25rem] object-cover object-center" />
          </div>


          {/* Product info */}
          <div className="w-1/2 max-sm:w-full min-h-[50rem]">

            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-3xl">{productOverview.name}</h1>
            <p className="text-3xl tracking-tight text-gray-900 dark:text-white">${productOverview.price}</p>

            {/* Reviews */}
            <div className="mt-3">
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        productOverview.rating > rating ? 'text-gray-900 dark:text-white' : 'text-gray-200 dark:text-gray-500',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <Link to="" className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-500 dark:hover:text-indigo-600">
                  20 reviews
                </Link>
              </div>
            </div>


            <div className='pt-5'>
              <div className="space-y-6">
                <p className="text-base text-gray-900 dark:text-gray-200">{productOverview.description}</p>
              </div>
            </div>

            <form className="mt-10"
            onSubmit={(e) => {
              e.preventDefault();
              if (isFormValid()) {
                // Proceed with form submission
                toggleShppingCart(productOverview);
                setSelectedColor(null);
                setSelectedSize(null);
              } else {
                alert('Please select both color and size.');
              }
            }}>
              {/* Colors */}
              <div>
                <h3 className="text-sm font-medium text-gray-900 dark:text-white">Color</h3>

                <RadioGroup className="mt-4">
                  <div className="flex items-center space-x-3">
                    {productOverview.color.map((color) => (
                      <RadioGroup.Option
                        key={color.id}
                        value={color}
                        className={({ active, checked }) =>
                          classNames(
                            color.selectedClass,
                            active && checked ? 'ring ring-offset-1' : '',
                            !active && checked ? 'ring-2' : '',
                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                          )
                        }
                      >
                        <span
                         onClick={() => {setCurrentImage(color.image); setSelectedColor(color)}}
                         aria-hidden="true"
                         className="h-8 w-8 rounded-full border border-black border-opacity-10"
                         style={{backgroundColor: color.class}}
                         />
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>

              {/* Sizes */}
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white">Size</h3>
                  <Link to="" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-500 dark:hover:text-indigo-600">
                    Size guide
                  </Link>
                </div>

                <RadioGroup className="mt-4">
                  <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                    {productOverview.size.map((size) => (
                      <RadioGroup.Option onClick={() => setSelectedSize(size)}
                        key={size.id}
                        value={size}
                        disabled={!size.stock}
                        className={({ active }) =>
                          classNames(
                            size.stock
                              ? 'cursor-pointer bg-white dark:bg-slate-600 dark:hover:bg-slate-700 text-gray-900 dark:text-white shadow-sm'
                              : 'cursor-not-allowed bg-gray-50 dark:bg-slate-600 text-gray-200 dark:text-white',
                            active ? 'ring-2 ring-indigo-500' : '',
                            'group relative flex items-center justify-center rounded-md border dark:border-none py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="span">{size.size}</RadioGroup.Label>
                            {size.stock ? (
                              <span
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-indigo-500' : 'border-transparent',
                                  'pointer-events-none absolute -inset-px rounded-md'
                                )}
                                aria-hidden="true"
                              />
                            ) : (
                              <span
                                aria-hidden="true"
                                className="pointer-events-none absolute -inset-px rounded-md border-2 border-gray-200"
                              >
                                <svg
                                  className="absolute inset-0 h-full w-full stroke-2 text-gray-200"
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="none"
                                  stroke="currentColor"
                                >
                                  <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                </svg>
                              </span>
                            )}
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>

              <div className="mt-10 flex gap-5">
                <button type="submit" className="w-full rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  Add to bag
                </button>

                <button onClick={() => toggleFavorite(productOverview)} type="button" class="text-gray-400 px-3 py-3 rounded-md hover:bg-gray-100 dark:hover:bg-slate-700 cursor-pointer">
                  <svg className={`flex-shrink-0 w-6 h-6 ${favorites.some(fav => fav.id === productOverview.id) ? 'text-red-500 dark:text-white' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"></path></svg>
                </button>
              </div>

            </form>

            <div className="space-y-4 mb-10">
              {productOverview.faq.map(item => (
                <AccordionItem key={item.id} item={item} />
              ))}
            </div>

          </div>
        </div>}
      </div>
    </div>
  );
}

export default Product_overview;
