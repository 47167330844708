import React from "react";
import Navigation from "./navigation/Navigation";
import Products_list from "./products_list/Products_list";


const Home_admin = () => {


    return(
        
        <div className="w-full min-h-full flex gap-16">
            <div className="w-60 h-screen max-sm:hidden">
                <Navigation />
            </div>
            <div className="w-full h-full bg-white">
                <Products_list />
            </div>
        </div>
    )
}

export default Home_admin;