import React, {useState, useEffect} from "react";
import { supabase } from "../../../api/supabaseClient";
import { Link } from "react-router-dom";


const sortOptions = [
  { value: 'created_at', label: 'Newest' },
  { value: 'rating', label: 'Most Popular' },
  { value: 'price', label: 'Price: High' },
];

const Products_list = () => {


  const [loading, setLoading] = useState();
  const [error, setError] = useState(false)
  const [products, setProducts] = useState()
  const [dataCounter, setDataCounter] = useState(3);
  const [orderBy, setOrderBy] = useState('created_at');
  const [selectedId, setSelectedId] = useState(1);


  let star = "★";



  useEffect(() => {

    setLoading(true)


    const fetchContact = async () => {
        const { data, error } = await supabase
            .from("products_list")
            .select()
            .order(orderBy, {ascending: false})


        if (error) {
            setError(true)
            setProducts(null)
            console.log(error)
        }
        else if (data) {
            setProducts(data)
            setError(false)
            console.log(data)
        }
    }

    fetchContact()

    setLoading(false)

}, [orderBy]);


     const handleOptionChange = (event) => {
       setOrderBy(event.target.value);
     };


      const handleSelectId = (id) => {
        setSelectedId(id);
      };


      const handleDelete = async (id) => {
        const { data, error } = await supabase
           .from('products_list')
           .delete()
           .eq('id', id)
           .select()

           if (error) {
             console.log(error)
           }
            else if (data) {
             console.log(data)
             setProducts(prevProducts => prevProducts.filter(product => product.id !== id));
           }
      }


  return (
    <div className="w-full h-full">
    <Link to="/admin-create" className="w-24 min-h-10 text-9xl text-blue-600 pl-4 cursor-pointer">+</Link>
    <div className="bg-white flex justify-center">
      <div className="w-4/5 min-h-10">
        <div className="border-b pb-3 flex justify-between items-center">

        <h2 className="text-2xl font-bold text-gray-900">All products</h2>

        <select className="min-w-14 focus:outline-none" onChange={handleOptionChange}>
        <option className="hidden" disabled selected>Sort</option>
        {sortOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      </div>

      {products && <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {products.slice(0, dataCounter).map(item => (
            <div key={item.id} onClick={() => handleSelectId(item.id)}>
              <div className="relative w-full h-80 overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={item.image}
                  alt={item.image}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
                <Link to={'/update-project/' + item.id}><svg className="absolute top-0 w-8 h-8 m-3 fill-black cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg></Link>
              </div>
              <div className="mt-4 flex justify-between items-start">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a>
                      {item.name}
                    </a>
                  </h3>
                  <p className="text-black">{star.repeat(item.rating)}</p>
                  <p className="text-sm font-medium text-gray-900">${item.price}</p>
                </div>

                <div className="w-40 h-9 flex justify-end cursor-pointer">
                    
                  <button onClick={() => handleDelete(item.id)} className="w-16 h-full bg-red-500 text-white rounded-md">Delete</button>
                
                </div>

              </div>
            </div>
          ))}
        </div>}
        <button className="blogs_box-more-button" onClick={() => setDataCounter(dataCounter + 1)}>More</button>
      </div>
    </div>
    </div>
  );
}

export default Products_list;
