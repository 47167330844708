import React, {useState, useEffect} from "react";
import Navigation from "../navigation/Navigation";
import Product_quickview from "../product_quickview/Product_quickview";
import Hamburger_menu from "../navigation/hamburger_menu/Hamburger_menu";

const Favorite_list = () => {


  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false)
  const [selectedId, setSelectedId] = useState(1);

  let star = "★";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const storedData = localStorage.getItem('favorites');
        if (storedData) {
          setData(JSON.parse(storedData));
          console.log(data)
        } else {
          setError(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
    
  }, []);



  const updateFavorites = (updatedFavorites) => {
    setData(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };



  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  const handleSelectId = (id) => {
    setSelectedId(id);
  };

    return(

    <div>
    <div className="sm:hidden">
    <Hamburger_menu />
    </div>
    <Navigation />
    <div className="bg-white dark:bg-slate-800 min-h-screen flex justify-center">
      <div className="w-4/5 min-h-10 mt-48 max-sm:mt-24">

      <div className="border-b pb-3 flex justify-start items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Favorit list</h2>
        <span className="flex justify-center items-center w-5 h-5 ml-2 text-sm font-semibold text-blue-800 bg-blue-200 rounded-full">
        {data.length}
        </span>
      </div>

      {data && <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {data.map(item => (
            <div key={item.id} className="group relative cursor-pointer" onClick={() => {openModal(); handleSelectId(item.id)}}>
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                <img
                  src={item.image}
                  alt={item.image}
                  className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                />
              </div>
              <div className="mt-4 flex justify-between items-start">
                <div>
                  <h3 className="text-sm text-gray-700 dark:text-white">
                    <a>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {item.name}
                    </a>
                  </h3>
                  <p className="text-black dark:text-white">{star.repeat(item.rating)}</p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">${item.price}</p>
                </div>

                <ul className="flex gap-2">

                {item.color.map(color => (

                
                  <li style={{backgroundColor: color.class}} className="w-4 h-4 border rounded-full"></li>
                

                ))}

                </ul>

              </div>
            </div>
          ))}
        </div>}
      </div>
    </div>
    <Product_quickview isOpen={isOpen} closeModal={closeModal} updateFavorites={updateFavorites} id={selectedId} />
    </div>
    );
}

export default Favorite_list;