import React, {useState} from "react";

const AccordionItem = ({ item }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
        <div className={`mt-10 border-y border-gray-200 dark:border-none dark:rounded-md dark:px-3 dark:bg-slate-900 overflow-hidden`}>
      <button
        className="flex justify-between items-center w-full py-5 text-left text-gray-700 dark:text-white bg-transparent border-0 focus:outline-none" onClick={() => setIsOpen(!isOpen)}>
        <span>{item.question}</span>
        <span className="text-2xl">{isOpen ? '-' : '+'}</span>
      </button>
      <div
        className={`transition-all max-h-0 ${
          isOpen ? 'max-h-screen' : ''} overflow-hidden`}>
        <div className={`px-4 py-2 text-black dark:text-gray-300`}>{item.answer}</div>
      </div>
    </div>
    );
  }

  export default AccordionItem;