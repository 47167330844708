import React, {useState, useEffect} from "react";
import { supabase } from "../../api/supabaseClient";
import Product_quickview from "../product_quickview/Product_quickview";
import { Link } from "react-router-dom";





const Promo_products = () => {


    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState();
    const [error, setError] = useState(false)
    const [promoProducts, setPromoProducts] = useState()
    const [selectedId, setSelectedId] = useState(1);

    let star = "★";


    useEffect(() => {

        setLoading(true)
    
    
        const fetchContact = async () => {
            const { data, error } = await supabase
                .from("products_list")
                .select()
    
    
            if (error) {
                setError(true)
                setPromoProducts(null)
                console.log(error)
            }
            else if (data) {
                setPromoProducts(data)
                setError(false)
                console.log(data)
            }
        }
    
        fetchContact()
    
        setLoading(false)
    
    }, []);

    const openModal = () => {
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
      };


      const handleSelectId = (id) => {
        setSelectedId(id);
      };


      const updateFavorites = (updatedFavorites) => {
        localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
      };
    
      
    return (
        <div className="w-full min-h-20 my-28">
            <section className="w-full h-6 flex justify-between items-center">
                <h2 className="text-black dark:text-white text text-2xl font-bold">Our products</h2>
                <Link to="/product-list" className="text-blue-600 dark:text-white font-semibold text-sm cursor-pointer">Browse all produts</Link>
            </section>
            <div className="w-full h-full flex max-sm:flex-col justify-between gap-5 mt-6">
                {promoProducts && promoProducts.slice(0, 3).map((item) => (
                <section key={item.id} className="w-96 max-sm:w-full h-full cursor-pointer" onClick={() => {openModal(); handleSelectId(item.id)}}>
                    <img className="w-full object-cover object-center rounded-lg" src={item.image} alt="" />
                    <div>
                    <h3 className="text-black dark:text-white font-semibold text-base pt-5">{item.name}</h3>
                    <p className="text-black dark:text-white">{star.repeat(item.rating)}</p>
                    <p className="text-gray-400 dark:text-white text-sm pt-1">${item.price}</p>
                    </div>
                </section>
                ))}
            </div>
            <Product_quickview isOpen={isOpen} closeModal={closeModal} updateFavorites={updateFavorites} id={selectedId} />
        </div>
    )
}

export default Promo_products;