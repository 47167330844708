import React, { useState } from "react";
import { motion } from "framer-motion";
import { navigationData } from "../navigationData";
import { Link } from "react-router-dom";
import Shopping_cart from "../../shopping_cart/Shopping_cart";
import DarkModeSwitch from "../../dark_mode_switch/DarkModeSwitch";

const sidebar = {
    open: {
      y: -60,
      width: "50vw",
      transition: {
        type: "spring",
        stiffness: 50,
      },
    },
    close: {
      y: -60,
      width: 0,
      }
  }


  const content = {
    open: {
      opacity: 1,
      transition:{
        duration: 0.5,
        delay: 0.5,
      },
    },
    close: {
        opacity: 0,
    }
  }

const Hamburger_menu = () => {

    const [open, setOpen] = useState(false);
    const [x, setX] = useState(false);
    const [removeScroll, setRevmoveScroll] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [updateCart, setUpdateCart] = useState();

    const openModal = () => {
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
      };


      const updateCart2 = () => {
        setUpdateCart(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));
      }


  const toggleMenu = () => {
    setX(!x);
    setOpen(!open);
    removeScroll ? document.body.style.overflow = "auto" : document.body.style.overflow = "hidden";
    setRevmoveScroll(!removeScroll)
  };

    return (
        <motion.nav className="sm:hidden fixed w-full h-0 z-20">
          <div className="bg-white dark:bg-slate-700 dark:bg-opacity-95 bg-opacity-95 w-full flex justify-between items-start p-4">
            <div onClick={toggleMenu} className="w-7 min-h-5 cursor-pointer z-50">
                <motion.div
                    className="w-full h-1 bg-black dark:bg-white"
                    animate={x ? { rotate: 45, y: 12 } : { rotate: 0 }}
                />
                <motion.div
                    className="w-full h-1 mt-2 bg-black dark:bg-white"
                    animate={x ? { opacity: 0 } : { opacity: 1 }}
                />
                <motion.div
                    className="w-full h-1 mt-2 bg-black dark:bg-white"
                    animate={x ? { rotate: -45, y: -12 } : { rotate: 0 }}
                />
            </div>
            <Link to="/" className="text-black dark:text-white leading-6 text-2xl uppercase font-normal">WaveWonder</Link>
            <div className="flex gap-4">
            <Link to="/favorite-list"><svg className="w-6 h-6 cursor-pointer dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg></Link>
            <svg onClick={() => {openModal(); updateCart2()}} className="w-6 h-6 cursor-pointer dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg>
            </div>
            </div>
            <motion.div className="h-screen bg-gray-200 dark:bg-gray-900 bg-opacity-80 backdrop-blur-md"
            variants={sidebar}
            initial="close"
            animate={open ? "open" : "close"}
            >
            <motion.ul className="w-full min-h-80 flex flex-col justify-end items-center gap-5">
                {navigationData.map((item, index) => (
                    <motion.li className="min-w-20 cursor-pointer" key={index}
                        variants={content}
                        initial="close"
                        animate={open ? "open" : "close"}
                    >
                        <Link onClick={toggleMenu} className="text-black dark:text-white text-lg font-mono" to={item.path}>{item.title}</Link>
                    </motion.li>
                ))}
            <motion.div className="w-20"
            variants={content}
            initial="close"
            animate={open ? "open" : "close"}
            >
            <DarkModeSwitch id="item2" />
            </motion.div>
            </motion.ul>
            </motion.div>
            <Shopping_cart isOpen={isOpen} closeModal={closeModal} updateCart={updateCart} />
        </motion.nav>
    );
}

export default Hamburger_menu;
